import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const subscriptionList = createAsyncThunk(
  "subscriptionList",
  async () => {
    try {
      const response = await AxiosInstance.get(`/subscription/list`);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const subscriptionListSlice = createSlice({
  name: "subscriptionList",
  initialState: {
    isLoading: false,
    subscriptionData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [subscriptionList.pending]: (state) => {
      state.isLoading = true;
    },
    [subscriptionList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.subscriptionData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [subscriptionList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default subscriptionListSlice.reducer;
